* {
  text-decoration: none;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.navbar-custom .navbar-toggler-icon {
  background-image: url('https://mdbootstrap.com/img/svg/hamburger7.svg?color=db467f');
}
.navbar-custom .navbar-toggler:focus,
.navbar-custom .navbar-toggler:active,
.navbar-custom .navbar-toggler-icon:focus {
  outline:none;
  box-shadow: none;
}

.navbar-nav {
  margin-left: 20px;
  display: flex;
  flex-direction: column;
}

.nav-link {
  margin: 5px;
}

.navLabelsActive {
  font-family:"Poppins";
  color:black;
  border-bottom: 3px solid #db467f;
  background-color: white;
  padding-bottom: 3px;
  cursor: pointer;
}

.navLabels {
  font-family:"Poppins";
  color:black;
  background-color: white;
  cursor: pointer;
}

.bg-NavBarItems {
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 0.8)
    ),
    url('https://st3.depositphotos.com/11601342/19037/v/1600/depositphotos_190372724-stock-illustration-vector-drink-pattern-drink-seamless.jpg');
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  white-space: nowrap;
}

.navbar-logo {
  white-space: nowrap;
  justify-self: start;
  cursor: pointer;
  padding-left:20px;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(4, auto);
  gap: 0px 10px;
  list-style: none;
  text-align: center;
  width: 70vw;
  justify-content: start;
  margin-right: 2rem;
  margin-bottom: 0;
}

.nav-links {
  color: black;
  text-decoration: none;
  padding: 0.3rem 0.3em;
}

.nav-links:hover {
  color: #db467f;
  background-color: white;
  transition: all 0.2s ease-out;
}

.nav-links-mobile {
  display: none;
}

.fa-bars {
  margin-right: 10px;
  color: #db467f;
}

.fa-times {
  margin-right: 12px;
  color: #db467f;
}

.menu-icon {
  display: none;
}

.btn-custom2 {
  position:absolute;
  right:20px;
  border-radius: 4px;
  text-decoration: none;
  font-size: 20px;
  background-color: #fe4f81 !important;
  color: white !important;
  font-weight: bold !important;
  width: 100px;
  margin-top:10px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.4);
  text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.6);
}

.navbarBrand{
  width:300px;
  margin-left:20px
}

@media screen and (max-width: 991px) {
  .navbarBrand{
    width:200px;
  }
  .NavBarItems {
    position: relative;
    font-size: 20px;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-menu.active {
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 30px;
    background: white;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    padding: 20px;
    width: 100%;
    display: table;
  }

  .nav-links:hover {
    background-color: #db467f41;
    border-radius: 0;
    color: black;
  }

  .menu-icon {
    display: block;
    position: absolute;
    right: 0;
    font-size: 1.8rem;
    cursor: pointer;
    margin-right: 5px;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    padding: 5px;
    border-radius: 4px;
    text-decoration: none;
    font-size: 20px;
    background-color: #fe4f81 !important;
    color: white !important;
    font-weight: bold !important;
    width: 250px;
    margin: 10px auto;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.4);
    text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.6);
  }

  .nav-links-mobile:hover {
    background: #fff;
    color: #db467f;
    transition: 250ms;
  }

  .btn-custom2{
    position:relative;
    margin-left:20px;
    margin-bottom: 20px;
  }


}

@media screen and (max-width: 480px) {
}
