@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;400;700&display=swap");

.loading_icon_container {
  position: fixed;
  top: 15vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 60vh;
}

svg {
  position: relative;
  width: 150px;
  height: 150px;
  animation: rotate 2s linear infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

svg circle {
  width: 100%;
  height: 100%;
  fill: none;
  stroke-width: 10;
  stroke: #eb4034;
  stroke-linecap: round;
  transform: translate(5px, 5px);
  stroke-dasharray: 440;
  stroke-dashoffset: 440;
  animation: animate 4s linear infinite;
}

.loading_text {
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  font-size: 1.8rem;
  color: #121212;
  margin-top: 20px;
  text-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
    rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}

@keyframes animate {
  0%,
  100% {
    stroke-dashoffset: 440;
    stroke: #db467f;
  }
  50% {
    stroke-dashoffset: 0;
  }
  50.1% {
    stroke-dashoffset: 880;
    stroke: #ee7752;
  }

  75% {
    stroke: #23a6d5;
  }
}
