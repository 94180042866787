.customMixDetailerContainer{
    position:absolute;
    background-color:rgba(251, 251, 251, 0.986);
    width:95vw;
    height:auto;
    margin-left: auto;
    margin-right:auto;
    left: 0;
    right: 0;
    margin-top:200px;
    border-radius:50px;
    box-shadow: 1px 2px 12px black;
    text-align:center;
    z-index:3;
    opacity:90%;
    font-family:"Poppins";
    text-shadow:2px 2px 3px rgba(0, 0, 0, 0.178);
}

.customMixDetailerContainer ion-icon{
    position:absolute;
    right:30px;
    top:20px;
    font-size:35px;
}

.customMixDetailerHeader{
    display:flex;
    flex-direction:row;
    justify-content:center;
    padding-top:20px;
}

.createMixForm-input{
    text-align:left;
    width:90%;
    margin: 0 auto;
    padding-bottom:20px;
}

.mixformLabel{
    font-size: 18px;
    text-align:left;
    font-family:"Poppins";
    width:100%;
    padding-top:12px;
    color:#242424;
    margin: 5px;
}

.customMixErrorMsg{
    text-align:center;
    color:red;
}

.ingredientsTag{
    margin-top:10px;
    display: inline-block;
    width:auto;
    padding-top:2px;
    padding-left:12px;
    padding-right:12px;
    height:30px;
    background-color:#fe4f8185;
    text-align:center;
    border-radius:10px;
    margin-right:1%;
    font-family:"Poppins";
}

.customIngredientsList ion-icon{
    font-size:22px;
    position:relative;
    top:3px;
    right:0px;
    bottom:0;
    left:2px;
}

.customIngredientsList{
    padding:0;
}

.uploadCustomPicButton{
    width:100px;
    height:50px;
    text-align:center;
}

.customCocktailImage{
    height:200px;
    width:auto;
    border-radius:100px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 3px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.sp_card_custommix {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 240px;
    width: 200px;
    border-radius: 15px;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
    font-family: "Montserrat", sans-serif;
    margin: 0 auto;
    cursor: pointer;
}

.customMixUploadButton{
    margin-top: 40px;
    display:flex;
    justify-content: center;
}

.modal-title {
    font-size: 30px !important;
}

.modal-body {
    margin-left: 20px;
    margin-right: 20px;
}

.modal-footer {
    justify-content: center !important;
    margin-bottom: 20px;
}