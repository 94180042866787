@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans&family=Poppins&display=swap');

*
{
  text-decoration: none;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.cockTailCircleCardContainer{
    text-align:center;
    font-size:20px;
    font-family:"Poppins";
    text-shadow: 1px 1px 6px rgba(0, 0, 0, 0.082);
}


.cocktailAvatar{
    width:175px;
    height:175px;
    object-fit:cover;
    border-radius:120px;
    margin: 30px 60px 20px 60px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 3px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    cursor: pointer;
}

.cocktailAvatar:hover{
  width:182px;
  height:182px;
}

.cockTailCircleCardContainer p {
  text-decoration: none;
  margin: 0;
  margin-top: 15px;
  margin-bottom: 15px;
  margin-left:10px;
  margin-right:10px;
}

@media screen and (max-width: 960px) {
  .cockTailCircleCardContainer{
    text-align:center;
    font-size:20px;
    font-family:"Poppins";
    text-shadow: 1px 1px 6px rgba(0, 0, 0, 0.082);
    margin-bottom: 0;
  }

  .cocktailAvatar{
    width:125px;
    height:125px;
    object-fit:cover;
    border-radius:120px;
    margin: 30px 32px 10px 32px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 3px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    cursor: pointer;
  }

  .cocktailAvatar:hover{
    width:132px;
    height:132px;
  }

  /* ul.cocktailFavouriteList {
    
  } */
}