*
{
  text-decoration: none;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.form-input {
  margin: 5px;
}

.btn-container {
  
}

