.categoryCircleCardContainer{
    position:relative;
}

.categoryAvatar{
    width:175px;
    height:175px;
    object-fit:cover;
    border-radius:120px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 3px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    margin: 30px 60px 20px 60px;
    cursor: pointer;
}

.categoryTitle{
    position:absolute;
    text-align:center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color:white;
    text-shadow: 3px 2px 10px black;
    font-size:25px;
    font-family:"Poppins"
}

@media screen and (max-width: 960px) {
    .categoryCircleCardContainer{
        position:relative;
    }
    
    .categoryAvatar{
        width:125px;
        height:125px;
        object-fit:cover;
        border-radius:120px;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 3px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
        margin: 30px 32px 20px 32px;
        cursor: pointer;
    }
    
    .categoryTitle{
        position:absolute;
        text-align:center;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color:white;
        text-shadow: 3px 2px 10px black;
        font-size:20px;
        font-family:"Poppins"
    }
}