*
{
  text-decoration: none;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

.topNav{
    background-image:linear-gradient(rgba(255,255,255,0.8), rgba(255,255,255,0.8)), url("https://st3.depositphotos.com/11601342/19037/v/1600/depositphotos_190372724-stock-illustration-vector-drink-pattern-drink-seamless.jpg");
    position:absolute;
    width:100vw;
    top:0px;
    height:100px;
    overflow:hidden;
    text-align:center;
    z-index: 1;
    border-bottom-style: solid;
    border-width: 1px;
    border-color: rgb(151, 151, 151);
}

.bottomNav{
    background-image:linear-gradient(rgba(255,255,255,0.8), rgba(255,255,255,0.8)), url("https://st3.depositphotos.com/11601342/19037/v/1600/depositphotos_190372724-stock-illustration-vector-drink-pattern-drink-seamless.jpg");
    position:absolute;
    width:100vw;
    bottom:0px;
    height:75px;
    overflow:hidden;
    text-align:center;
    z-index: 1;
}

.bottomNavFeedbackLeft{
    background-image: linear-gradient(-45deg, #ee7752, #db467f, #23a6d5, #23d5ab);
    background-size: 350% 350%;
    animation: gradient 12s ease infinite;
    position:absolute;
    width:30vw;
    height:10px;
    top:0px;
    z-index: 2;
}

.bottomNavFeedbackMiddle{
    background-image: linear-gradient(-45deg, #ee7752, #db467f, #23a6d5, #23d5ab);
    background-size: 350% 350%;
    animation: gradient 12s ease infinite;
    position:absolute;
    width:30vw;
    height:10px;
    margin:0;
    transform: translate(50%);
    right:50%;
    z-index: 2;
}

.bottomNavFeedbackRight{
    background-image: linear-gradient(-45deg, #ee7752, #db467f, #23a6d5, #23d5ab);
    background-size: 350% 350%;
    animation: gradient 12s ease infinite;
    position:absolute;
    width:30vw;
    height:10px;
    right:0%;
    top:0px;
    z-index: 2;
}

.navBackButton{
    height:50px;
    width: 70px;
    position:absolute;
    top:25px;
    left:0px;
    background-color:rgba(255,255,255,0);
    border:0px;
    font-size:40px;
    color: #fe4f81;
}

.navMyBarButton{
    position:absolute;
    width:30vw;
    height:50px;
    margin-top:10px;
    left:0px;
    background-color:rgba(255,255,255,0);
    border:0px;
    font-size:40px;
    color:rgb(151, 151, 151);
}

.navScanButton{
    width:35vw;
    height:50px;
    margin:0 auto;
    margin-top:10px;
    background-color:rgba(255,255,255,0);
    border:0px;
    font-size:40px;
    color:rgb(151, 151, 151);
}
.navCommunityButton{
    position:absolute;
    width:30vw;
    height:50px;
    margin-top:10px;
    right:0px;
    background-color:rgba(255,255,255,0);
    border:0px;
    font-size:40px;
    color:rgb(151, 151, 151);
}

.topNav h1 {
    font-size: 38px;
    font-weight: bold;
    text-align: center;
    margin: 25px;
}