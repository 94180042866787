.homeContainer {
    background-image: linear-gradient(-45deg, #ee7752, #db467f, #23a6d5, #23d5ab);
    background-size: 350% 350%;
    height:100vh;
    animation: gradient 12s ease infinite;
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

.homePage h1{
    text-align:center;
    transform:translate(0%,100%);
    margin-bottom:15%;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    color:white;
    text-shadow: 2px 3px 1px rgb(109, 109, 109);
}

.homePage p{
    text-align:left;
    margin-top:10%;
}

.homeMenu {
    font-size: 40px;
    font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    text-indent:10%;
    text-shadow: 3px 4px 1px rgb(109, 109, 109);
    color:white;
    margin-top: 2%;
}

.menuCardDiv{
    margin-top:2%;
    box-shadow: 1px 5px 100px rgb(0, 0, 0);
}

.menuCardDiv img{
    object-fit:cover;
    height:7vh;
    filter: none; 
    image-rendering: auto;
    image-rendering: crisp-edges;
    image-rendering: pixelated;
    opacity:15%;
    object-position: 0% 25%;
}