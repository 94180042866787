.cocktailDetailerContainer{
    position:absolute;
    background-color:rgba(251, 251, 251, 0.986);
    width:95vw;
    height:auto;
    margin-left: auto;
    margin-right:auto;
    left: 0;
    right: 0;
    margin-top:200px;
    border-radius:50px;
    box-shadow: 1px 2px 12px black;
    text-align:center;
    z-index:3;
}

.modalImgContainer{
    margin-top:10px;
    display: flex;
    justify-content:center;
}
.cocktailDetailerContainerImg{
    width:170px;
    border-radius:100px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 3px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.cocktailDetailerDataIngredients{
    text-align: center;
}

.cocktailDetailerContainer p{
    margin-left:5%;
    margin-right:5%;
}

.cocktailDetailerContainer ion-icon{
    position:absolute;
    right:30px;
    top:30px;
    font-size:35px;
}

.cocktailDetailerHeader{
    display:flex;
    flex-direction:row;
    justify-content:center;
}

.detailsFavoriteButton{
    background-color:rgba(240, 248, 255, 0);
    border-color: rgba(211, 222, 233, 0);
    color:gray;
}


.detailsFavoriteButton ion-icon{
    position:relative;
    left:0;
    top:0;
    font-size:30px;
}

.cocktailIngredientsTag{
    margin-bottom:5px;
    display: inline-block;
    width:auto;
    padding-top:2px;
    padding-left:12px;
    padding-right:12px;
    height:30px;
    background-color:rgb(218, 218, 218);
    text-align:center;
    border-radius:10px;
    margin-right:1%;
    font-family:"Poppins";
}

.cocktailDetailerDataInstructions{
    text-align: center;
    margin-top:5px;
    border-top: 2px solid rgb(250, 250, 250);
    padding-top:10px;
}

.btn-removeMyBarItemButton{
    text-align: center;
    margin: 0 auto;
    margin-top: 10px;
    margin-bottom:30px;
    border-radius: 4px;
    font-size: 25px;
    background-color: #fb2323 !important;
    color: white !important;
    width: 50px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.4);
    text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.6);
}

.userSubmittedName{
    text-align:center;
    font-family:"Poppins";
    font-size:18px;
}