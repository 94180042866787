.cocktail_scan_List {
  width: 100vw;
  height: 100vh;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.searchedCocktailList {
  padding: 0;
  width: 100vw;
  display: flex;
  overflow: auto;
}
